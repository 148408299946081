.page {
  &.isPinning {
    cursor:
      url('data:image/svg+xml,<svg width="30" height="30" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 34V36H4H19C28.3888 36 36 28.3888 36 19C36 9.61116 28.3888 2 19 2C9.61116 2 2 9.61116 2 19V34Z" fill="%238358E1"/><path d="M2 34V36H4H19C28.3888 36 36 28.3888 36 19C36 9.61116 28.3888 2 19 2C9.61116 2 2 9.61116 2 19V34Z" fill="white" fill-opacity="0.5"/><path d="M2 34V36H4H19C28.3888 36 36 28.3888 36 19C36 9.61116 28.3888 2 19 2C9.61116 2 2 9.61116 2 19V34Z" stroke="white" stroke-width="4"/></svg>')
        0 30,
      auto !important;
  }
}
.header {
  width: 100%;
  height: 60px;
  background-color: var(--backgroundLight);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .back {
    cursor: pointer;
    height: 60px;
    padding: 18px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s;
    > p {
      font-size: 12px;
      font-weight: 600;
    }
    &:hover {
      transform: scale(1.2);
      transition: all 0.2s;
    }
  }
  .fileName {
    font-size: 12px;
    font-weight: 600;
  }
  .rightFilter {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-right: 16px;
  }
}

.container {
  min-height: calc(100vh - 60px); // 100vh - header height
  display: flex;
  justify-content: center;
  .left {
    flex: 1;
    max-width: calc(100vw - 300px);
    display: flex;
    justify-content: center;
    .reviewFrame {
      width: 100%;
      .frame {
        padding: 10px;
        margin-bottom: 8px;
        justify-content: center;
        .mediasContainer {
          height: calc(100vh - 60px - 10px - 4px - 60px - 10px - 8px - 184px); // 184 = 89 + 95
          width: 100%;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.currentVideo {
            height: calc(100vh - 60px - 10px - 4px - 60px - 10px - 8px - 184px - 36px);
          }
          .sliderContainer {
            width: 100%;
            height: 100%;
            [class~="slick-slider"],
            [class~="slick-list"],
            [class~="slick-track"] {
              height: 100%;
            }
            [class~="slick-slide"] {
              > div {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .mediaContainer {
              position: relative;
              width: unset !important;
              display: inline-flex !important;
              max-height: 100%;
              outline: none;
              img {
                border-radius: 24px;
                width: auto !important;
                max-width: 100%;
                max-height: 100%;
                user-select: none;
              }
              div:has(> video) {
                border-radius: 24px;
                overflow: hidden;
                width: auto !important;
                max-width: 100%;
                max-height: 100%;
                height: auto !important;
                user-select: none;
              }
              video {
                object-fit: unset;
                border-radius: 24px;
                overflow: hidden;
              }
              .pin {
                display: none;
                position: absolute;
                width: 30px;
                height: 30px;
                &.display {
                  display: block !important;
                }
                &.resolved {
                  filter: brightness(0.6);
                }
                &.active {
                  path {
                    fill: var(--primary);
                  }
                }
              }
              .videoWrapper {
                position: relative;
                .loadingVideoReady {
                  position: absolute;
                  left: 0;
                  top: -95%;
                  width: 100%;
                  z-index: 9;
                }
              }
            }
          }
        }
        .videoTimelineWrapper {
          position: relative;
          .slider {
            height: 10px;
            [class~="MuiSlider-rail"] {
              background-color: var(--backgroundLight);
            }
            [class~="MuiSlider-track"] {
              background-color: var(--primary);
            }
  
            [class~="MuiSlider-thumb"] {
              height: 26px;
              width: 6px;
              background-color: var(--white);
              border-radius: 4px;
              box-shadow: unset;
            }
          }
          .timelinePin {
            cursor: pointer;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            transform: translateY(-70%);
            path {
              stroke: var(--backgroundDark);
            }
          }
        }
        .toolbar {
          width: 100%;
          height: 60px;
          background-color: var(--backgroundLight);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 0 16px;
          > svg {
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
              transform: scale(1.2);
              transition: all 0.2s;
            }
          }
          .videoToolbar {
            display: flex;
            gap: 8px;
            align-items: center;
            > svg {
              cursor: pointer;
              transition: all 0.2s;
              &:hover {
                transform: scale(1.2);
                transition: all 0.2s;
              }
            }
            .videoRepeat {
              &.enable {
                path {
                  fill: var(--primary);
                }
              }
            }
          }
          .volumeSlider {
            margin-right: auto;
            max-width: 120px;
            margin-left: 8px;
            [class~="MuiSlider-rail"] {
              background-color: var(--backgroundDark);
            }
            [class~="MuiSlider-track"] {
              background-color: var(--primary);
              border: none;
            }
            [class~="MuiSlider-thumb"] {
              background-color: var(--primary);
              box-shadow: unset;
            }
            &.hidden {
              visibility: hidden;
            }
          }
        }
      }
      .thumbnailsContainer {
        max-width: calc(769px - 48px); // 48px - arrows
        margin: 0 auto 8px;
        [class~="slick-slide"] {
          > div {
            opacity: 0.3;
            padding: 0 4px;
            > img {
              cursor: pointer;
              border-radius: 4px;
              width: 72px !important;
              height: 73px;
              object-fit: cover;
            }
          }
          &[class~="slick-current"] {
            > div {
              opacity: 1;
            }
          }
        }
  
        [class~="slick-arrow"] {
          border-radius: 50%;
          background-color: var(--backgroundLight);
          &[class~="slick-disabled"] {
            opacity: 0.3;
            cursor: auto;
          }
        }
  
        &.onlyOneMedia {
          [class~="slick-track"]:only-child {
            display: flex;
            justify-content: center;
          }
        }
      }
      .commentContainer {
        display: flex;
        justify-content: center;
        .comment {
          height: 89px;
          width: 100%;
          max-width: 769px;
          padding: 8px;
          background-color: var(--backgroundLight);
          border-radius: 8px;
          display: flex;
          gap: 12px;
          .avatar {
            flex-shrink: 0;
            > img {
              width: 26px;
              height: 26px;
              border-radius: 50%;
              object-fit: cover;
              object-position: center;
            }
          }
          .input {
            display: flex;
            flex-direction: column;
            flex: 1;
            > textarea {
              border: none;
              resize: none;
              outline: none;
              color: var(--white);
              background-color: transparent;
              width: 100%;
              height: 100%;
            }
            .errorMessage {
              font-size: 12px;
              color: var(--danger);
            }
          }
          .action {
            flex-shrink: 0;
            display: flex;
            align-items: flex-end;
            > div {
              display: flex;
              align-items: center;
              gap: 10px;
              > svg {
                cursor: pointer;
                transition: all 0.2s;
                &:hover {
                  transform: scale(1.2);
                  transition: all 0.2s;
                }
              }
            }
          }
        }
      }
    }
  }
  .right {
    height: calc(100vh - 60px); // 100vh - header height
    overflow: auto;
    width: 300px;
    padding: 0 8px;
    flex-shrink: 0;
    border-top: 1px solid var(--backgroundDark);
    background-color: var(--backgroundLight);
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    > hr {
      margin: 0;
      opacity: 1;
      border-color: var(--white);
    }
    .filter {
      flex-shrink: 0;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      [class~="dropdown-toggle"] {
        &:after {
          display: none;
        }
        > svg {
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.2);
            transition: all 0.2s;
          }
        }
      }
      [class~="dropdown-item"] {
        > span {
          font-size: 12px;
        }
      }
    }
    .noReview {
      font-size: 12px;
      text-align: center;
      margin: auto 0;
    }
    .commentBox {
      padding: 16px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      transition: all 0.2s;
      .title {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 8px;
          > img {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
          > p {
            max-width: 150px; // calculate this by yourself :)
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        > div:last-child {
          display: flex;
          align-items: center;
          gap: 8px;
          > svg {
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
              transform: scale(1.2);
              transition: all 0.2s;
            }
          }
        }
      }
      .notResolved {
        path {
          fill: var(--white);
        }
      }
      .content {
        font-size: 12px;
        font-weight: 500;
        word-break: break-all;
      }
      .subResourceChip {
        font-size: 12px;
        height: 19px;
        font-weight: 500;
        background-color: var(--primary);
        color: var(--white);
        width: fit-content;
      }
      .info {
        color: var(--gray);
        font-size: 12px;
        font-weight: 500;
        word-break: break-all;
      }
      .isPinning {
        path {
          fill: var(--green);
        }
      }
      .pinRed {
        path {
          fill: var(--danger);
        }
      }
      &:not(.replyThread):hover,
      &.active {
        cursor: pointer;
        background-color: var(--tableRowActive);
        transition: all 0.2s;
      }
      &.replyThread {
        padding: 8px 16px 8px 32px;
      }
      ~ hr {
        border-color: var(--white);
        opacity: 1;
      }
      .inputWrapper {
        display: flex;
        position: relative;
        flex: 1;
        align-items: center;
        gap: 8px;
        > input {
          width: 100%;
          outline: none;
          background: var(--backgroundDark);
          border: none;
          border-radius: 4px;
          color: var(--gray);
          padding: 12px 45px 12px 12px;
          font-size: 12px;
        }
        > .replyButton {
          position: absolute;
          right: 42px;
          top: 50%;
          transform: translateY(-50%);
          &:hover {
            transform: translateY(-50%) scale(1.2);
          }
        }
        > .closeButton {
          path {
            fill: var(--white);
          }
        }
        > svg {
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    .replyAction {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 16px;
      > p {
        font-size: 12px;
        font-weight: 500;
        color: var(--gray);
        cursor: pointer;
      }
      > button {
        font-size: 12px;
        font-weight: 500;
        background: var(--backgroundDark);
        outline: none;
        border: none;
        height: 38px;
        color: var(--gray);
        padding: 0 12px;
        border-radius: 4px;
        &:hover {
          background-color: var(--greyActive);
        }
      }
    }
    .replyWrapper {
      display: flex;
      align-items: center;
      padding: 0 16px;
      gap: 8px;
      margin-top: 16px;
      > .avatar {
        flex-shrink: 0;
        > img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }
      > .inputWrapper {
        display: flex;
        position: relative;
        flex: 1;
        > input {
          width: 100%;
          outline: none;
          background: var(--backgroundDark);
          border: none;
          border-radius: 4px;
          color: var(--gray);
          padding: 12px 45px 12px 12px;
          font-size: 12px;
        }
        > svg {
          cursor: pointer;
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.2s;
          &:hover {
            transform: translateY(-50%) scale(1.2);
          }
        }
      }
    }
    .errorMessage {
      font-size: 12px;
      font-weight: 500;
      margin-top: 4px;
      margin-left: 52px;
      color: var(--danger);
    }
    .showMoreReply {
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      color: var(--gray);
      text-align: right;
      width: fit-content;
      margin-left: auto;
    }
  }
}

.messageMenu {
  [class~="MuiPaper-root"] {
    background-color: var(--backgroundDark);
    color: var(--white);
  }
  ul {
    padding: 4px 0;
  }
}

.revert {
  transform: rotate(180deg);
}
