.container {
  padding-bottom: 40px;
}

.noPublishedPipeline {
  min-height: calc(100vh - 24px - 40px - 24px - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;
  width: 100%;
  margin: auto;
  max-width: 500px;
  > p {
    font-size: 38px;
    font-weight: 600;
    line-height: 100%;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 28px;
  margin-bottom: 24px;
  white-space: nowrap;
}

.toolbarLeft {
  display: flex;
  align-items: center;
  gap: 16px;
}

.toolbarRight {
  display: flex;
  align-items: center;
  gap: 28px;
  .taskViewButton {
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--white);
    padding: 6.5px 16px 6.5px 24px;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.25s;
    &:hover {
      background: var(--greyActive);
      transition: all 0.25s;
    }
  }
}

.searchInput {
  width: 280px;
  color: var(--gray);
  background: var(--backgroundLight);
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 16px;
  padding: 10px 16px;
  outline: none;
  &::-ms-input-placeholder {
    color: var(--gray);
  }
  &::placeholder {
    color: var(--gray);
  }
}

.resourceSidebarContainer {
  position: fixed !important;
  top: 0;
  right: 0;
  min-height: 100vh;
  border: none !important;
  z-index: 1000;
  box-shadow: var(--leftBoxShadow);
  &[class~='ps-collapsed'] {
    width: 0 !important;
    min-width: 0 !important;
  }
  .resourceSidebar {
    white-space: nowrap;
    padding: 0 40px 40px;
    direction: ltr;
    background-color: var(--backgroundLight);
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .backContainer {
      position: sticky;
      top: 0px;
      right: 0;
      width: 100%;
      padding: 40px 0 24px;
      background: var(--backgroundLight);
      z-index: 1;
      > svg {
        cursor: pointer;
        transition: all 0.25s;
        &:hover {
          transform: scale(1.2);
          transition: all 0.25s;
        }
      }
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .multiplierStackContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      [class~='MuiStack-root'] {
        gap: 30px;
        [class~='Mui-checked'] {
          color: var(--green) !important;
        }
        [class~='MuiSwitch-track'] {
          opacity: 0.5;
          background-color: var(--greenActive) !important;
        }
        [class~='Mui-checked'] + [class~='MuiSwitch-track'] {
          opacity: 1;
        }
      }
    }
    .nameMultiplierButton {
      width: 100%;
      font-size: 12px;
      color: white;
      background: transparent;
      border: 1px solid var(--primary);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 6px;
      transition: all 0.2s;
      > svg {
        > path {
          fill: var(--primary);
        }
      }
      &:hover {
        background: var(--greyActive);
        transition: all 0.2s;
      }
    }
    .nameListContainer {
      margin: 12px 0 24px;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: var(--primary);
        }
      }
      > div {
        border-radius: 8px;
        border: 1px solid var(--primary);
        padding: 6px 10px 6px 8px;
        display: flex;
        align-items: flex-start;
        gap: 8px;
        font-size: 14px;
        font-weight: 500;
        color: var(--gray);
        > p {
          white-space: wrap;
          word-break: break-all;
        }
        &:hover {
          cursor: default;
          > svg:last-child {
            cursor: pointer;
            transition: all 0.25s;
            &:hover {
              transform: scale(1.2);
              transition: all 0.25s;
            }
          }
        }
      }
    }
    .label {
      font-size: 12px;
      font-weight: 600;
      margin: 0 2px 2px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .stageListContainer {
      margin-bottom: 24px;
      > div {
        cursor: pointer;
        margin-bottom: 8px;
        padding: 6px 12px;
        min-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid var(--primary);
        transition: all 0.25s;
        > svg {
          width: 20px;
          height: 20px;
        }
        > p {
          word-break: break-all;
          font-weight: 600;
        }
        &.active {
          background: var(--primary) !important;
          transition: all 0.25s;
          &:hover {
            filter: brightness(1.1);
            transition: all 0.25s;
          }
        }
        &:hover {
          background: var(--greyActive);
          transition: all 0.25s;
        }
      }
    }
  }
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 12px;
  > div:last-child {
    flex: 1;
  }
}

.table {
  overflow: auto;
  .row {
    display: flex;
    align-items: stretch;
  }
  .column {
    flex-shrink: 0;
    min-width: 172px;
    flex: 1;
  }
  .name {
    flex: 0;
    min-width: 225px;
  }
  .thumbnail {
    flex: 0;
    min-width: 80px;
  }
  .imageThumbnail {
    width: 60px;
    height: 32px;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
  }
  .priority {
    flex: 0;
    min-width: 126px; // same with complexity
  }
  .complexity {
    flex: 0;
    min-width: 126px; // 94 + 32
  }
  .head {
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: sticky;
    top: 0;
    width: 100%;
    min-width: fit-content;
    background: var(--backgroundDark);
    z-index: 2;
    .column {
      cursor: default;
      padding: 7px 16px;
      font-size: 12px;
      font-weight: 600;
      color: var(--gray);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(4) {
        box-shadow: var(--rightBoxShadow);
      }
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        cursor: pointer;
        z-index: 1;
        position: sticky;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 2px 32px 2px 16px;
        justify-content: center;
        border-radius: 8px;
        background: var(--backgroundDark); // for position sticky
        transition: all 0.25s;
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 8px;
          .revert {
            transform: rotate(180deg);
          }
        }
        &:hover {
          background: var(--backgroundLight);
          transition: all 0.25s;
        }
      }
      &:first-child {
        cursor: auto;
        pointer-events: none;
      }
    }
    .priority,
    .complexity {
      padding: 2px 16px !important;
    }
  }
  .bodyContainer {
    min-height: 500px;
    height: calc(100vh - 24px - 40px - 24px - 38px - 24px - 32px - 56px - 40px - 20px); // 20px for 2 horizontal scrollbars
  }
  .body {
    border-radius: 8px 8px 0 0;
    padding: 0 20px;
    min-height: 100%;
    min-width: fit-content;
    width: 100%;
    background: var(--backgroundLight);
    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .row {
      > .column:first-child {
        border-radius: 8px 0 0 8px;
      }
      > .column:nth-child(2) {
        svg {
          path,
          rect {
            fill: var(--white);
          }
          width: 0;
          transition: all 0.3s;
        }
      }
      > .column:last-child {
        border-radius: 0 8px 8px 0;
      }
      &:nth-child(odd) {
        > .column {
          background: var(--backgroundLight);
          transition: all 0.3s;
        }
      }
      &:nth-child(even) {
        > .column {
          background: var(--rowBackground);
          transition: all 0.3s;
        }
      }
      &:hover {
        > .column {
          background: var(--tableRowActive);
          transition: all 0.3s;
          &:nth-child(2) {
            svg {
              width: 20px;
              margin-right: 8px;
              transition: all 0.3s;
            }
          }
        }
      }
    }
    .column {
      font-weight: 500;
      padding: 12px 16px;
      word-break: break-all;
      > p {
        flex: 1;
      }
      &:first-child {
        display: flex;
        align-items: center;
        left: 0;
        padding: 8px 10px;
      }
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        z-index: 1;
        position: sticky;
      }
      &:nth-child(2),
      &:nth-child(3) {
        cursor: pointer;
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        > p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      &:nth-child(4) {
        box-shadow: var(--rightBoxShadow);
        > div {
          width: 100%;
        }
      }
    }
  }
  .status {
    padding: 0 8px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      cursor: pointer;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 4px 0 4px 8px;
      font-size: 12px;
      font-weight: 500;
      > div {
        min-width: 20%;
        padding: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 14px;
        position: relative;
        > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
        &::before {
          content: '';
          width: 2px;
          height: 32px;
          position: absolute;
          left: 0;
          top: -6px;
        }
      }
    }
  }
  .notStarted {
    > div {
      border: 2px solid var(--gray);
      > div {
        &::before {
          background: var(--gray);
        }
      }
    }
  }
}

.tableFooter {
  border-radius: 0 0 8px 8px;
  padding: 12px 48px;
  color: var(--white);
  background: var(--backgroundLight);
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    div,
    button {
      color: var(--white);
    }
    [class~='Mui-selected'] {
      background: var(--primary);
    }
  }
}

.layout {
  min-width: fit-content;
  min-height: calc(100vh - 24px - 40px - 24px - 40px - 10px); // 10px for a horizontal scrollbar
  display: flex;
  align-items: stretch;
  gap: 24px;
  .left {
    width: 530px; // should be at least 530px
    display: flex;
    flex-direction: column;
    .leftHeader {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 12px;
      > svg {
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.2);
          transition: all 0.2s;
        }
      }
    }
    .leftTable {
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      .row {
        display: flex;
        align-items: stretch;
      }
      .column {
        flex-shrink: 0;
        flex: 1;
      }
      .thumbnail {
        min-width: 80px;
        padding: 8px 10px !important;
      }
      .name {
        min-width: 158px;
      }
      .imageThumbnail {
        width: 60px;
        height: 32px;
        object-fit: cover;
        object-position: center;
        border-radius: 4px;
      }
      .priority {
        flex: 0;
        min-width: 126px; // same with complexity
      }
      .complexity {
        flex: 0;
        min-width: 126px; // 94 + 32
      }
      .head {
        height: 32px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        position: sticky;
        top: 0;
        width: 100%;
        min-width: fit-content;
        background: var(--backgroundDark);
        z-index: 1;
        .column {
          cursor: default;
          padding: 8.5px 16px;
          font-size: 12px;
          font-weight: 600;
          color: var(--gray);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:first-child,
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            cursor: pointer;
            height: 30px;
            display: flex;
            align-items: center;
            padding: 2px 32px 2px 16px;
            justify-content: center;
            border-radius: 8px;
            transition: all 0.25s;
            > div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              gap: 8px;
              .revert {
                transform: rotate(180deg);
              }
            }
            &:hover {
              background: var(--backgroundLight);
              transition: all 0.25s;
            }
          }
        }
        .priority,
        .complexity {
          padding: 2px 16px !important;
        }
      }
      .bodyContainer {
        min-height: calc(100% - 32px);
        &.hasFilters {
          height: calc(
            100vh - 24px - 40px - 24px - 38px - 12px - 38px - 38px - 16px - 12px - 32px - 56px - 40px - 10px
          ); // 10px for a horizontal scrollbar
        }
        height: calc(100vh - 24px - 40px - 24px - 38px - 12px - 32px - 56px - 40px - 10px); // 10px for a horizontal scrollbar
      }
      .body {
        border-radius: 8px 8px 0 0;
        padding: 0 20px;
        min-height: 100%;
        min-width: fit-content;
        width: 100%;
        background: var(--backgroundLight);
        &.empty {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .row {
          &.active {
            .column {
              background: var(--tableRowActive) !important;
              transition: all 0.3s;
              &:nth-child(2) {
                svg {
                  width: 20px;
                  margin-right: 8px;
                }
              }
            }
          }
          > .column:first-child {
            border-radius: 8px 0 0 8px;
          }
          > .column:nth-child(2) {
            svg {
              path,
              rect {
                fill: var(--white);
              }
              width: 0;
              transition: all 0.3s;
            }
          }
          > .column:last-child {
            border-radius: 0 8px 8px 0;
          }
          &:nth-child(odd) {
            > .column {
              background: var(--backgroundLight);
              transition: all 0.3s;
            }
          }
          &:nth-child(even) {
            > .column {
              background: var(--rowBackground);
              transition: all 0.3s;
            }
          }
          &:hover {
            > .column {
              background: var(--tableRowActive);
              transition: all 0.3s;
              &:nth-child(2) {
                svg {
                  width: 20px;
                  margin-right: 8px;
                  transition: all 0.3s;
                }
              }
            }
          }
        }
        .column {
          font-weight: 500;
          padding: 12px 16px;
          word-break: break-all;
          > p {
            flex: 1;
          }
          &:nth-child(2),
          &:nth-child(3) {
            cursor: pointer;
          }
          &:nth-child(2) {
            display: flex;
            align-items: center;
            > p {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
          &:nth-child(4) {
            > div {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    // max-width: calc(100vw - 60px - 40px - 400px - 24px - 40px);
    min-width: 900px;
    .rightHeader {
      height: 269px;
      padding: 24px 16px;
      border-radius: 8px;
      background: var(--backgroundLight);
      display: flex;
      gap: 24px;
      overflow-x: auto;
      .resourceTitle {
        line-height: 100%;
        font-weight: 600;
        margin-bottom: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .resourceDescription {
        font-size: 12px;
        font-weight: 500;
        color: var(--gray);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .rightHeaderLeft {
        flex: 1;
        display: flex;
        gap: 20px;
        .assetImage {
          cursor: pointer;
          position: relative;
          > img {
            width: 331px;
            height: 221px;
            object-fit: cover;
            object-position: center;
            border-radius: 24px;
            box-shadow: var(--blackBoxShadow);
          }
          > div {
            position: absolute;
            right: 8px;
            bottom: 8px;
            display: flex;
            align-items: center;
            gap: 6px;
            padding: 4px 8px;
            border-radius: 8px;
            background: var(--black);
            > p {
              font-weight: 500;
            }
          }
        }
        .uploadImagesPlaceholder {
          cursor: pointer;
          width: 331px;
          height: 221px;
          object-fit: cover;
          object-position: center;
          border-radius: 24px;
          box-shadow: var(--blackBoxShadow);
          transition: all 0.2s;
          &:hover {
            opacity: 0.75;
            transition: all 0.2s;
          }
        }
        .resourceInfo {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-width: 906px; // max width available when there is no imagesList
          overflow-x: auto;
          .resourceProgress {
            display: flex;
            align-items: center;
            gap: 4px;
            > svg {
              width: 20px;
              height: 20px;
              > rect:first-child {
                fill: var(--black);
              }
              > rect:last-child {
                stroke: var(--black);
              }
              &.active {
                > rect:first-child {
                  fill: var(--pink);
                }
                > rect:last-child {
                  stroke: var(--pink);
                }
              }
            }
            > div {
              width: 36px;
              height: 4px;
              background: var(--black);
              &.active {
                background: var(--pink);
              }
            }
          }
          .resourceSupervisors {
            display: flex;
            align-items: center;
            gap: 10px;
            > div {
              display: flex;
              align-items: center;
              gap: 8px;
              > img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: cover;
                object-position: center;
              }
            }
          }
          .actions {
            display: flex;
            align-items: center;
            gap: 16px;
            .uploadImagesButton {
              width: fit-content;
              display: flex;
              align-items: center;
              gap: 8px;
              > svg {
                width: auto;
                height: 24px;
              }
            }
          }
        }
      }
      .rightHeaderRight {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        text-align: right;
        white-space: nowrap;
        .uidContainer {
          display: flex;
          align-items: center;
          gap: 4px;
          > svg {
            cursor: pointer;
            transition: all 0.25s;
            &:hover {
              filter: brightness(1.5);
              transition: all 0.25s;
            }
          }
        }
      }
    }
    .emptyRightHeader {
      height: 269px;
      padding: 24px 16px;
      border-radius: 8px;
      background: var(--backgroundLight);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rightTable {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      &.loading {
        position: relative;
        .headColumn {
          user-select: none;
          pointer-events: none;
        }
      }
      .dropContainer {
        min-height: 400px;
        height: calc(100vh - 24px - 40px - 24px - 269px - 8px - 40px - 10px);
        display: flex;
        overflow: auto;
        &.hideBody {
          &::before,
          &::after {
            opacity: 0;
          }
          .bodyColumn {
            display: none;
          }
        }
        &::before {
          content: '';
          display: block;
          position: sticky;
          top: 32px;
          margin-top: 32px;
          left: 0;
          background: var(--backgroundLight);
          width: 20px;
          border-radius: 8px 0 0 8px;
          min-height: calc(100vh - 24px - 40px - 24px - 269px - 8px - 32px - 40px - 10px);
        }
        &::after {
          content: '';
          display: block;
          position: sticky;
          margin-top: 32px;
          top: 32px;
          right: 0;
          background: var(--backgroundLight);
          width: 20px;
          border-radius: 0 8px 8px 0;
          min-height: calc(100vh - 24px - 40px - 24px - 269px - 8px - 32px - 40px - 10px);
        }
      }
      .emptyRightTable {
        width: 100%;
        min-height: calc(400px - 32px);
        height: calc(100vh - 24px - 40px - 24px - 269px - 32px - 8px - 40px - 10px);
        position: absolute;
        left: 0;
        top: 32px;
        border-radius: 8px;
        background: var(--backgroundLight);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .dragItem {
        flex: 1;
        user-select: none;
        height: max-content;
        &.isDragging {
          position: absolute !important;
          min-height: 400px;
          height: calc(100vh - 24px - 40px - 24px - 269px - 8px - 40px - 10px) !important;
          overflow: hidden;
        }
        .headColumn {
          height: 32px;
          font-size: 12px;
          font-weight: 600;
          padding: 0 16px;
          color: var(--gray);
          background: var(--backgroundDark);
          position: sticky;
          display: flex;
          top: 0;
          align-items: center;
          z-index: 1;
          &.hoverable {
            padding: 0;
            justify-content: center;
            > div {
              cursor: pointer;
              padding: 0 16px;
              width: 100%;
              height: 30px;
              display: flex;
              align-items: center;
              gap: 8px;
              border-radius: 8px;
              transition: all 0.25s;
              .revert {
                transform: rotate(180deg);
              }
              &:hover {
                background: var(--backgroundLight);
                transition: all 0.25s;
              }
            }
          }
        }
        .bodyColumn {
          min-height: calc(100vh - 24px - 40px - 24px - 269px - 8px - 32px - 40px - 10px);
          background: var(--backgroundLight);
          position: relative;
          > div:nth-child(even) {
            background: var(--rowBackground);
          }
          > div:nth-child(odd) {
            background: var(--backgroundLight);
          }
          > div {
            height: 48px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            position: relative;
            transition: all 0.3s;
            &.hovering {
              background: var(--tableRowActive);
              transition: all 0.3s;
            }
            .actions {
              display: flex;
              align-items: center;
              gap: 16px;
              svg {
                cursor: pointer;
                opacity: 0.3;
                transition: all 0.3s;
                &:hover {
                  opacity: 1;
                  transition: all 0.3s;
                }
              }
              .review {
                cursor: pointer;
                position: relative;
                > p {
                  position: absolute;
                  bottom: -4px;
                  right: -6px;
                  background-color: var(--primary);
                  font-size: 10px;
                  font-weight: 500;
                  border-radius: 50%;
                  padding: 0 5px;
                }
                &.active {
                  > svg {
                    opacity: 1;
                  }
                }
              }
            }
            .stage {
              font-weight: 500;
            }
            .assignedContainer {
              cursor: pointer;
              width: 100%;
              display: flex;
              align-items: center;
              gap: 8px;
              > img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: cover;
                object-position: center;
              }
              > p {
                font-size: 12px;
              }
            }
            .duration {
              font-size: 12px;
              font-weight: 500;
            }
          }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: calc(400px - 32px);
            background: var(--backgroundLight);
          }
        }
        &:first-child {
          .bodyColumn {
            > div {
              border-radius: 8px 0 0 8px;
            }
          }
        }
        &:last-child {
          .bodyColumn {
            > div {
              border-radius: 0 8px 8px 0;
            }
          }
        }
      }
      .party {
        left: unset !important;
        top: unset !important;
        right: 0 !important;
        bottom: 0 !important;
      }
    }
  }
}

.deleteImageIcon {
  cursor: pointer;
  width: 48px;
  height: 48px;
  padding: 12px;
  transition: all 0.1s;
  > path,
  > rect {
    fill: var(--white);
  }
  &:hover {
    filter: brightness(1.5);
    transition: all 0.1s;
  }
}

.customValueContainer {
  display: block !important;
  div {
    display: flex;
    align-items: flex-end;
    gap: 4px;
  }
  p {
    font-size: 12px;
    font-weight: 600;
    color: var(--gray);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  input {
    position: absolute;
  }
}

.customOption {
  display: flex;
  align-items: center;
  gap: 6px;
  > input {
    cursor: pointer;
    position: relative;
    border: 2px solid var(--white);
    border-radius: 2px;
    background: none;
    line-height: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: text-top;
    height: 18px;
    width: 18px;
    appearance: none;
    -webkit-appearance: none;
    &:checked {
      background: var(--primary);
      border-color: var(--primary);
      &::before {
        display: block;
      }
    }
    &::before {
      content: '';
      display: none;
      position: absolute;
      right: 50%;
      top: 50%;
      width: 5px;
      height: 10px;
      border: solid var(--backgroundDark);
      border-width: 0 2px 2px 0;
      margin: -1px;
      transform: rotate(45deg) translate(-50%, -50%);
      z-index: 1;
    }
  }
  > img {
    margin-right: 2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
