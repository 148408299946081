.container {
  height: 100vh;
  background-color: var(--sidebarBackground);
  position: relative;
}

.menu {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .divider {
    margin: 16px auto;
    width: 21px;
    height: 1px;
    background-color: var(--pinkBackground);
  }
}

.item {
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 8px auto;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--lightPinkBackground);
    > svg {
      &.pathFill path {
        fill: var(--lightPink);
      }
      &.rectFill rect {
        fill: var(--lightPink);
      }
      &.pathStroke path {
        stroke: var(--lightPink);
      }
      &.projectIcon {
        > rect:nth-child(1),
        > rect:nth-child(2),
        > rect:nth-child(3),
        > rect:nth-child(4) {
          fill: var(--lightPink);
        }
        > rect:nth-child(5) {
          stroke: var(--lightPink);
        }
        > rect:nth-child(6) {
          stroke: var(--lightPink);
        }
      }
      &.userIcon {
        > rect,
        > path:nth-child(2) {
          stroke: var(--lightPink);
        }
        > path:nth-child(3) {
          fill: var(--lightPink);
        }
      }
    }
  }
  &:has(> svg.active) {
    background-color: var(--pinkBackground);
  }
  > svg.active {
    &.pathFill path {
      fill: var(--pink);
    }
    &.rectFill rect {
      fill: var(--pink);
    }
    &.pathStroke path {
      stroke: var(--pink);
    }
    &.projectIcon {
      > rect:nth-child(1),
      > rect:nth-child(2),
      > rect:nth-child(3),
      > rect:nth-child(4) {
        fill: var(--pink);
      }
      > rect:nth-child(5) {
        stroke: var(--pink);
      }
      > rect:nth-child(6) {
        stroke: var(--pink);
      }
    }
    &.userIcon {
      > rect,
      > path:nth-child(2) {
        stroke: var(--pink);
      }
      > path:nth-child(3) {
        fill: var(--pink);
      }
    }
  }
}

.logoItem {
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 8px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:hover) {
    svg {
      path {
        fill: var(--gray);
      }
    }
  }
}

.userItem {
  cursor: pointer;
  position: relative;
  margin: auto auto 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  .desktopAppIcon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
  }
  .avatar {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin: 0 7px;
  }
  [class~='dropdown-toggle'] {
    &::after {
      display: none !important;
    }
  }
  [class~='dropdown-menu'] {
    // prevent delayed placement
    inset: auto auto 0px 0px;
    transform: translate3d(54px, 0px, 0px);
  }
}

.tooltip {
  width: 248px;
  height: 68px;
  padding: 8px 12px !important;
  margin-left: 13px !important;
  background-color: var(--chartBackgroundLv1) !important;
  border-radius: 8px !important;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > p {
      cursor: default;
      font-size: 12px;
      font-weight: 500;
    }
    > svg {
      cursor: pointer;
      > path {
        fill: var(--gray);
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    gap: 16px;
    > svg {
      cursor: pointer;
    }
  }
  [class~='MuiTooltip-arrow'] {
    color: var(--chartBackgroundLv1);
  }
}
