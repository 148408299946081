.container {
  min-height: 100vh;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;
  color: var(--white);
  span {
    font-size: 50px;
    letter-spacing: 6px;
    background: -webkit-linear-gradient(#ffffff, #38495a);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .redirectContent {
    font-size: 24px;
  }
  @media only screen and (max-width: 575.98px) {
    span {
      font-size: 36px;
    }
    .redirectContent {
      font-size: 18px;
    }
  }
}
