.container {
  cursor: pointer;
  width: 100px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  padding: 3px 16px;
  border-radius: 18px;
  border: 3px solid var(--gray);
}
