.popover {
  z-index: 999;
}

.paper {
  background: transparent !important;
  overflow: visible !important;
  border-radius: 8px !important;
  box-shadow: var(--blackBoxShadow) !important;
}

.menu {
  background: var(--black);
  color: var(--white);
  border-radius: 8px;
  padding: 16px 24px;
  .complexityItem {
    cursor: pointer;
    width: 151px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    border-width: 2px;
    border-style: solid;
    border-radius: 16px;
    padding: 6.5px 16px;
    transition: all 0.2s;
    &:hover {
      background: var(--greyActive);
      transition: all 0.2s;
    }
  }
  .divider {
    border-color: var(--gray);
    opacity: 1;
  }
  .editLabel {
    cursor: pointer;
    text-align: center;
    margin-top: 16px;
    font-weight: 500;
  }
  .editContainer {
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }
  .complexityInput {
    padding: 0 16px;
    input {
      width: 100px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: var(--white);
      border-bottom: 1px solid var(--white);
    }
  }
}
