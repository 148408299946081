@import '~bootstrap/scss/bootstrap';
@import './fonts.scss';
@import './config.scss';
@import './gantt.scss';

:root {
  --gradient: transparent linear-gradient(90deg, #8357e1 0%, #5373df 100%);
  --primary: #8358e1;
  --progressTrackBackground: #8358e14d;
  --secondary: #470fbf;
  --pink: #d052ff;
  --lightPink: #dca6f0;
  --pinkBackground: #24142f;
  --lightPinkBackground: #302736;
  --sidebarBackground: #0C0B0F;
  --trackBarBackground: #111015;
  --black: #18171e;
  --white: #ffffff;
  --blue: #66afff;
  --red: #cc3939;
  --yellow: #fcd965;
  --gray: #94a3b8;
  --grey: #cbd5e1;
  --greyActive: #cccccc25;
  --green: #69cf96;
  --greenActive: #bdfceb;
  --backgroundLight: #252941;
  --backgroundMedium: #1a1b26;
  --backgroundDark: #18171e;
  --reactFlowBorder: #252941;
  --nodeBackground: #34233e;
  --nodeOutline: #8358e1;
  --warning: #e57722;
  --danger: #ff0000;
  --rowBackground: #21253b;
  --chartRowBackground: #21253b4d;
  --tableRowActive: #3f3259;
  --chartBackgroundLv1: #1c203b;
  --chartBackgroundLv2: #21253b;
  --ganttProgressbarLv1: #4c5161;
  --ganttProgressbarLv3: #c7b4f0;

  --primaryBoxShadow: var(--primary) 0px 2px 20px 0px;
  --blackBoxShadow: #00000040 0px 4px 4px 0px;
  --dropdownBoxShadow: #94a3b830 0px 0px 8px 0px;
  --rightBoxShadow: var(--black) 6px 0 5px -5px;
  --leftBoxShadow: var(--black) -6px 0 5px -5px;
}

html,
body {
  margin: 0;
  padding: 0 !important;
  overflow-x: hidden !important;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: var(--white);
  background-color: var(--backgroundDark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .cursor-pointer {
    cursor: pointer;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 6px; // old: 6px
    background: var(--trackBarBackground);
  }
  ::-webkit-scrollbar {
    width: 10px; // old: 6px
    height: 10px; // old: 4px
    border-radius: 6px; // old: 6px
    background: var(--trackBarBackground);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: none;
    background: var(--backgroundLight);
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  p {
    margin-bottom: 0;
  }
  a {
    color: var(--white);
  }
}
