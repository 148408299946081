.container {
  margin-bottom: 24px;
  > label {
    font-size: 12px;
    font-weight: 600;
    margin: 0 2px 2px;
  }
  > p {
    font-size: 12px;
    margin: 2px 2px 0;
    color: var(--danger);
    white-space: wrap;
  }
}
