@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loadingContainer {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.isLoading {
  -webkit-animation: rotation 3s infinite linear;
  animation: rotation 3s infinite linear;
}

.loadingText {
  font-weight: 400;
  font-size: 16px;
  margin-top: 14px;
  margin-bottom: 30px;
}
