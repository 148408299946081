.container {
  [class~='MuiPaper-root'] {
    border-radius: 12px;
    color: var(--white);
    background: var(--backgroundLight);
    width: 100%;
  }
  [class~='MuiTypography-root'] {
    padding: 24px;
  }
  [class~='MuiDialogActions-root'] {
    padding: 24px;
    gap: 12px 24px;
    > button {
      margin: 0;
    }
  }
  .pipelineTemplateDescriptionContainer {
    label {
      font-size: 12px;
      font-weight: 600;
      margin: 0 2px 2px;
    }
    .description {
      font-size: 12px;
      font-weight: 300;
      color: var(--gray);
      margin-bottom: 14px;
    }
    .pipelineTemplateDescription {
      cursor: default;
      font-size: 12px;
      font-weight: 300;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid var(--primary);
      .statuses {
        margin-bottom: 16px;
        > div {
          opacity: 0.5;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 8px;
          > div {
            font-size: 12px;
            font-weight: 500;
            border-width: 2px;
            border-style: solid;
            border-radius: 16px;
            padding: 6px 24px;
          }
        }
      }
      .chip {
        margin-bottom: 16px;
        > div {
          opacity: 0.5;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 8px;
          > div {
            padding: 4px 8px;
            border-radius: 4px;
            border: 1px solid var(--primary);
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
