.container {
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.15);
    transition: all 0.2s;
  }
}

.popover {
  z-index: 999;
}
