.container {
  padding-bottom: 40px;
}

.description {
  font-size: 12px;
  font-weight: 300;
  color: var(--gray);
  margin-bottom: 24px;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;
  > input {
    width: 280px;
    color: var(--gray);
    background: var(--backgroundLight);
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 16px;
    padding: 10px 16px;
    outline: none;
    &::-ms-input-placeholder {
      color: var(--gray);
    }
    &::placeholder {
      color: var(--gray);
    }
  }
}

.table {
  overflow: auto;
  .row {
    display: flex;
    align-items: center;
  }
  .column {
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.avatar {
      max-width: 64px;
      > img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
    &.email {
      min-width: 275px;
    }
    &.role {
      min-width: 150px;
    }
    &.actionButtons {
      max-width: 104px;
    }
  }
  .head {
    min-width: 1100px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: sticky;
    top: 0;
    background: var(--backgroundDark);
    .column {
      cursor: default;
      padding: 8.5px 16px;
      font-size: 12px;
      font-weight: 600;
      color: var(--gray);
      &.sort {
        cursor: pointer;
        height: 30px;
        padding: 2px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        transition: all 0.25s;
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 8px;
          .revert {
            transform: rotate(180deg);
          }
        }
        &:hover {
          background: var(--backgroundLight);
          transition: all 0.25s;
        }
      }
    }
  }
  .bodyContainer {
    min-width: 1100px;
    min-height: 500px;
    height: calc(100vh - 24px - 40px - 4px - 18px - 24px - 38px - 24px - 32px - 40px - 10px); // 10px for horizontal scrollbar
  }
  .body {
    border-radius: 8px;
    padding: 0 20px;
    min-height: 100%;
    width: 100%;
    background: var(--backgroundLight);
    &.empty {
      min-width: 1100px; // min-width of table head
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .row {
      border-radius: 8px;
      transition: all 0.2s;
      &:nth-child(even) {
        background: var(--rowBackground);
      }
      &:hover {
        background: var(--tableRowActive);
        transition: all 0.2s;
      }
    }
    .column {
      font-weight: 500;
      padding: 12px 16px;
      word-break: break-all;
      &.avatar {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.status {
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      }
      &.pending {
        color: var(--warning);
      }
      &.accepted {
        color: var(--green);
      }
      &.actionButtons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
        svg {
          cursor: pointer;
          fill: var(--white);
          transition: all 0.2s;
          &:hover {
            transform: scale(1.2);
            transition: all 0.2s;
          }
        }
      }
    }
  }
}
