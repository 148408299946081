.container {
  max-width: 500px;
  min-height: calc(100vh - 40px - 24px - 4px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 60px;
}

.description {
  font-size: 12px;
  font-weight: 300;
  color: var(--gray);
  margin-bottom: 24px;
}

.projectTemplateDescriptionContainer {
  margin-bottom: 24px;
  label {
    font-size: 12px;
    font-weight: 600;
    margin: 0 2px 2px;
  }
  .projectTemplateDescription {
    cursor: default;
    font-size: 12px;
    font-weight: 300;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid var(--primary);
    .statuses {
      margin-bottom: 16px;
      > div {
        opacity: 0.5;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
        > div {
          font-size: 12px;
          font-weight: 500;
          border-width: 2px;
          border-style: solid;
          border-radius: 16px;
          padding: 6px 24px;
        }
      }
    }
    .chip {
      margin-bottom: 16px;
      > div {
        opacity: 0.5;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
        > div {
          padding: 4px 8px;
          border-radius: 4px;
          border: 1px solid var(--primary);
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}

.datePickerWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  > label {
    font-size: 12px;
    font-weight: 600;
    margin: 0 2px 2px;
  }
  .input {
    height: 20px;
    background: transparent;
    border: none;
    outline: none;
    color: var(--white);
    font-size: 12px;
    font-weight: 300;
    width: 100%;
    text-align: left;
    padding: 10px 16px;
    height: 38px;
    &.placeholder {
      color: var(--gray);
    }
  }
  [class~='react-datepicker-wrapper'] {
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    color: var(--white) !important;
    background: transparent !important;
    border-radius: 8px;
    border: 1px solid var(--primary);
  }
  .calendar {
    [class~='react-datepicker__triangle'] {
      &::after {
        border-bottom-color: var(--backgroundDark) !important;
      }
      &::after,
      &::before {
        left: -40px;
      }
    }
    [class~='react-datepicker__header'] {
      background: var(--backgroundDark);
    }
    [class~='react-datepicker__month'] {
      margin: 0;
      padding: 8px;
      background: var(--backgroundDark);
    }
    [class~='react-datepicker__current-month'],
    [class~='react-datepicker__day-name'],
    [class~='react-datepicker__day'] {
      color: var(--white);
    }
    [class~='react-datepicker__day--today'] {
      color: var(--primary);
    }
    [class~='react-datepicker__day'] {
      border: 1px solid transparent;
      transition: all 0.15s;
      &:hover {
        background: transparent;
        border: 1px solid var(--white);
        transition: all 0.15s;
      }
    }
    [class~='react-datepicker__day--keyboard-selected'] {
      background: transparent;
      transition: all 0.15s;
    }
    [class~='react-datepicker__day--selected'] {
      font-weight: 600;
      color: var(--white);
      background: var(--primary);
      transition: all 0.15s;
    }
  }
  .errorMessage {
    margin: 2px 2px 0;
    font-size: 12px;
    color: var(--danger);
    white-space: wrap;
  }
}

.footerContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > hr {
    margin: 8px 0;
  }
}
