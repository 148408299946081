$height: 83vh;
$height_percent: 100%;

.container {
  display: flex;
  flex-direction: column;
  height: $height;
  padding: 0 40px;
  .header {
    margin: 24px 0;
  }

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 28px;
    margin-bottom: 24px;
    white-space: nowrap;
  }

  .toolbarLeft {
    display: flex;
    // flex-wrap: wrap;
    align-items: right;
    width: 100%;
    gap: 16px;
    :nth-child(5) {
      margin-right: auto
    }
  }

  .searchInput {
    width: 280px;
    color: var(--gray);
    background: var(--backgroundLight);
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 16px;
    padding: 10px 16px;
    outline: none;
    &::-ms-input-placeholder {
      color: var(--gray);
    }
    &::placeholder {
      color: var(--gray);
    }
  }

  .switchContainer {
    border-color: hsl(0, 0%, 80%);
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    box-sizing: border-box;
    padding-left: 24px;
    border: none;
    background: var(--backgroundLight);
    [class~='MuiStack-root'] {
      > p {
        font-size: 12px;
        font-weight: 600;
        color: var(--gray);
      }
      [class~='Mui-checked'] {
        color: var(--green) !important;
      }
      [class~='MuiSwitch-track'] {
        opacity: 0.5;
        background-color: var(--greenActive) !important;
      }
      [class~='Mui-checked'] + [class~='MuiSwitch-track'] {
        opacity: 1;
      }
    }
  }

  .chart {
    flex: 1;
    height: $height_percent;
    // margin-bottom: 24px;
    [class~='e-gridcontent'] {
      background-color: var(--backgroundDark) !important;
      height: $height_percent !important;
    };
    [class~='e-grid'],
    [class~='e-table'],
    [class~='e-headercell'],
    [class~='e-chart-root-container'],
    [class~='e-timeline-header-container'],
    [class~='e-content'] {
      background-color: var(--backgroundDark) !important;
    }
    [class~='e-chart-row-border'] {
      border-width: 2px 0px 0px 0px !important;
      border-color: var(--chartRowBackground) !important;
    }
    [class~='e-active'] {
      background-color: var(--rowBackground) !important;
    }
    [class~='e-line-container-cell'] {
      border-color: var(--chartRowBackground);
    }
    [class~='e-splitter'] {
      height: $height_percent !important;
      border: none !important;
    }
    [class~='e-gantt-splitter'] {
      height: $height_percent !important;
      border: none !important;
    }
    [class~='e-rowcell'],
    [class~='e-headercell'],
    [class~='e-timeline-header-container'],
    [class~='e-gridheader'] {
      border: none !important;
    }
    [class~='e-line-container-cell'] {
      border-color: var(--rowBackground) !important;
    }
    [class~='e-timeline-top-header-cell'] {
      border-width: 0 1px 0 0 !important;
      border-color: var(--rowBackground) !important;
    }
    [class~='e-split-bar'] {
      width: 6px !important;
      background-color: var(--backgroundLight) !important;
    }
    [class~='e-split-bar-active'],
    [class~='e-split-bar-hover'] {
      [class~='e-resize-handler'] {
        color: var(--primary) !important;
      }
    }
    [class~='e-timeline-header-container'] {
      > table:first-child {
        th {
          background-color: var(--chartRowBackground) !important;
        }
      }
      > table:last-child {
        th {
          background-color: var(--backgroundDark);
        }
      }
    }
    [class~='e-gantt'] {
      [class~='e-grid'] {
        [class~='e-focused'] {
          box-shadow: unset !important;
        }
      }
    }
    [class~='e-treegrid'] {
      [class~='e-treecell'],
      p {
        font-size: 16px;
        line-height: normal;
      }
    }
    [class~='e-headercell'],
    [class~='e-rowcell'] {
      padding: 0 16px 0 !important;
    }
    [class~='e-row'][aria-expanded] {
      background-color: var(--chartBackgroundLv1);
      &:has([class~='e-icons'][class~='e-none'] + [class~='e-icons']) {
        background-color: var(--chartBackgroundLv2);
      }
    }
    [class~='e-gantt-tree-grid'] {
      height: 100%;
      [class~='e-control'] {
        height: 100%;
        [class~='e-gridcontent'] {
          height: 100%;
          [class~='e-content'] {
            height: 100% !important;
          }
        }
      }
    }
    [class~='e-content'] {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    [class~='e-label'] {
      z-index: 2 !important;
      color: var(--gray) !important;
      font-size: 16px !important;
    }
    [class~='e-gantt-child-taskbar'] {
      background-color: var(--ganttProgressbarLv3) !important;
      outline: none !important;
      border-radius: 4px !important;
      [class~='e-gantt-child-progressbar-inner-div'] {
        background-color: var(--ganttProgressbarLv3) !important;
      }
    }
    [class~='e-chart-row'][class*='level0'] {
      [class~='e-gantt-parent-taskbar'] {
        background-color: var(--ganttProgressbarLv1) !important;
        height: 12px !important;
        outline: none !important;
        border-radius: 20px !important;
      }
      // [class~='e-gantt-child-progressbar-inner-div'] {
      //   background-color: var(--ganttProgressbarLv1) !important;
      // }
      // [class~='e-label'] {
      //   display: none !important;
      // }
    }
    [class~='e-chart-row'][class*='level1'] {
      [class~='e-gantt-parent-taskbar'] {
        background-color: var(--primary) !important;
        height: 12px !important;
        outline: none !important;
        border-radius: 2px !important;
      }
      // [class~='e-gantt-child-progressbar-inner-div'] {
      //   background-color: var(--primary) !important;
      // }
      // [class~='e-label'] {
      //   display: none !important;
      // }
    }
    
    [class~='e-gantt-toolbar'],
    [class~='e-toolbar-items'],
    [class~='e-toolbar-item'] {
      background: unset;
      border: none;
    }
    [class~='e-toolbar-items'] {
      width: 100%;
      > [class~='e-template']:nth-child(2) {
        margin-right: auto;
      }
    }
    [class~='e-hscroll-content'] {
      display: flex;
      width: 100%;
      > [class~='e-template']:nth-child(2) {
        margin-right: auto;
      }
    }
    [class~='e-hscroll-bar'] {
      width: 100%;;
    }
    [class~='e-scroll-nav'] {
      display: none !important;
    }
    [class~='e-gantt-toolbar'] {
      text-align: right;
      margin-bottom: 4px;
      
    }
    // &.hideProjectLayer {
    //   [class~='e-chart-row'][class*='level0'] {
    //     [class~='e-gantt-parent-taskbar'] {
    //       background-color: var(--primary) !important;
    //       height: 12px !important;
    //       outline: none !important;
    //       border-radius: 20px !important;
    //     }
    //     [class~='e-gantt-child-progressbar-inner-div'] {
    //       background-color: var(--primary) !important;
    //     }
    //     [class~='e-label'] {
    //       display: none !important;
    //     }
    //   }
    //   [class~='e-chart-row'][class*='level1'] {
    //     [class~='e-gantt-child-taskbar'] {
    //       background-color: var(--ganttProgressbarLv3) !important;
    //       outline: none !important;
    //       border-radius: 20px !important;
    //       [class~='e-gantt-child-progressbar-inner-div'] {
    //         background-color: var(--ganttProgressbarLv3) !important;
    //       }
    //     }
    //     [class~='e-label'] {
    //       display: block !important;
    //       z-index: 2 !important;
    //       color: var(--gray) !important;
    //       font-size: 16px !important;
    //     }
    //   }
    // }
    .dateRangePicker {
      background-color: transparent !important;
    }
    .progressBar {
      color: var(--primary);
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: 5px solid var(--progressTrackBackground);
        border-radius: 50%;
        z-index: -1;
      }
    }
    .assigneeImg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      border-radius: 50%;
    }
    .assigneeChip {
      width: fit-content;
      padding: 2px 6px 2px 2px;
      border-radius: 20px;
      background: rgba(76, 81, 97, 0.5);
      p {
        font-size: 16px;
        line-height: normal;
      }
    }
    .toolbarButton {
      display: flex;
      align-items: center;
      gap: 4px;
      height: 30px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
[class~='e-tooltip-wrap'] {
  * {
    color: var(--white) !important;
  }
  &:not([class~='e-gantt-tooltip']) {
    display: none !important;
  }
}
.footer {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 95%;
  height: 3%;
  align-items: center;
  justify-content: flex-end;
}
.instructions {
  color: var(--gray);
  font-size: 12px;
  margin-left: auto;
}
