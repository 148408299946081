.container {
  [class~='MuiPaper-root'] {
    border-radius: 12px;
    color: var(--white);
    background: var(--backgroundLight);
    width: 100%;
  }
  [class~='MuiTypography-root'] {
    padding: 24px;
  }
  [class~='MuiDialogContent-root'] {
    padding-bottom: 0;
  }
  [class~='MuiDialogActions-root'] {
    padding: 0 24px 24px;
    gap: 12px 24px;
    > button {
      margin: 0;
    }
  }
  .emailListContainer {
    margin: 12px 0 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: var(--primary);
      }
    }
    > div {
      border-radius: 8px;
      border: 1px solid var(--primary);
      padding: 6px 10px 6px 8px;
      word-break: break-all;
      display: flex;
      align-items: flex-start;
      gap: 8px;
      font-size: 14px;
      font-weight: 500;
      color: var(--gray);
      &:hover {
        cursor: default;
        > svg:last-child {
          cursor: pointer;
          transition: all 0.25s;
          &:hover {
            transform: scale(1.2);
            transition: all 0.25s;
          }
        }
      }
    }
  }
}
