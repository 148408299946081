.customMenuSelectUser {
  z-index: 1300;
  margin-top: 4px !important;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: var(--dropdownBoxShadow);
  [class~='MuiPaper-root'] {
    color: var(--white);
    background: var(--backgroundDark);
  }
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  [class~='MuiList-root'] {
    padding: 16px 8px;
  }
  [class~='MuiButtonBase-root'] {
    font-family: inherit;
    border-radius: 4px;
    transition: all 0.2s;
    &:hover {
      background: var(--rowBackground);
      transition: all 0.2s;
    }
  }
}
