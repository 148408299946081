.container {
  margin-bottom: 24px;
  > label {
    font-size: 12px;
    font-weight: 600;
    margin: 0 2px 2px;
  }
  .dropzone {
    cursor: pointer;
    padding: 9px 16px;
    width: 280px;
    height: auto;
    aspect-ratio: 7/4;
    border-radius: 8px;
    border: 1px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 300;
    color: var(--gray);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
    }
    &.borderNone {
      border: none;
      padding: 0;
    }
  }
  > p {
    font-size: 12px;
    margin: 2px 2px 0;
    color: var(--danger);
  }
}
