.container {
  width: calc(100vw - 60px - 240px) !important;
  margin-left: 240px !important;
}

.sidebar {
  z-index: 1000;
  position: fixed;
  width: 240px;
  min-height: 100vh;
  background: var(--backgroundMedium);
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    cursor: pointer;
    border-radius: 12px;
    width: 100%;
    font-weight: 500;
    padding: 14px 24px;
    transition: all 0.25s;
    &.active {
      background: var(--backgroundLight);
    }
    &.disabled {
      user-select: none;
      pointer-events: none;
      color: var(--gray);
    }
    &:hover {
      background: var(--backgroundLight);
      transition: all 0.25s;
    }
  }
}
