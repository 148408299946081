.container {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-color: hsl(0, 0%, 80%);
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  padding: 0 24px;
  width: 230px;
  border: none;
  background: var(--backgroundLight);
  min-height: 38px;

  input,
  input::placeholder {
    cursor: pointer;
    font-size: 12px !important;
    font-weight: 600 !important;
    color: var(--gray) !important;
  }
  [class~="e-input-group"] {
    margin: 0 !important;
    border: none !important;
    &::after,
    &::before {
      display: none;
    }
  }
  [class~="e-input-group-icon"] {
    margin: 0 !important;
  }
  [class~="e-input-group-icon"][class~="e-active"] {
    color: var(--primary) !important;
  }
}

[class~="e-daterangepicker"] {
  z-index: 9999 !important;
  margin-left: 60px;
}
[class~="e-popup"],
[class~="e-presets"],
[class~="e-calendar-container"],
[class~="e-calendar"],
[class~="e-footer"],
[class~="e-range-header"] {
  background-color: var(--backgroundDark) !important;
}
[class~="e-popup"] {
  box-shadow: var(--dropdownBoxShadow) !important;
  border-radius: 4px !important;
  overflow: hidden;
}
[class~="e-cell"][class~="e-selected"] {
  > span {
    background-color: var(--primary) !important;
    color: var(--white) !important;
  }
}
[class~="e-cell"][class~="e-today"] {
  > span {
    border-color: var(--primary) !important;
    color: var(--primary) !important;
  }
}
[class~="e-list-item"][class~="e-active"] {
  color: var(--primary) !important;
}
[class~="e-apply"]:not(:disabled) {
  color: var(--white) !important;
  &:hover {
    background-color: var(--greyActive) !important;
  }
}
[class~="e-presets"] {
  max-height: 240px !important;
}