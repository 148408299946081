.title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 32px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  > div {
    cursor: pointer;
    width: 100%;
    min-height: 60px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    border-radius: 8px;
    border: 2px solid var(--primary);
    transition: all 0.2s;
    svg {
      width: 24px;
      height: 24px;
    }
    p {
      font-weight: 500;
      width: 186px;
    }
    &:hover {
      box-shadow: var(--primaryBoxShadow);
      transition: all 0.2s;
    }
  }
}
