.container {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  background: var(--nodeBackground);
  border: 1px solid var(--nodeOutline);
  border-radius: 12px;
  font-weight: 500;
  white-space: nowrap;
}
