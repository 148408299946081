.popover {
  z-index: 999;
}

.paper {
  background: transparent !important;
  overflow: visible !important;
  border-radius: 8px !important;
  box-shadow: var(--dropdownBoxShadow) !important;
}

.menu {
  background: var(--black);
  color: var(--white);
  border-radius: 8px;
  padding: 12px;
  > div {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    width: 100px;
    padding: 6px 12px;
    border-radius: 8px;
    transition: all 0.2s;
    &:hover {
      background: var(--rowBackground);
      transition: all 0.2s;
    }
  }
}
