.container {
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  white-space: nowrap;
  &.isPipelineStages {
    height: 60px;
  }
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 12px 24px;
  .title {
    font-size: 24px;
    font-weight: 700;
  }
}

.addWorkspaceButton {
  width: 100%;
  padding: 8px 12px 0;
  > button {
    width: 100%;
    background: transparent;
    border: 1px solid var(--white);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    transition: all 0.2s;
    > svg {
      > path {
        fill: var(--white);
      }
    }
    &:hover {
      background: var(--greyActive);
      transition: all 0.2s;
    }
  }
}

.selectAlt {
  font-weight: 600;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 16px;
  .promptInput {
    position: relative;
    > input {
      width: 429px;
      color: var(--gray);
      background: var(--backgroundLight);
      font-size: 12px;
      font-weight: 600;
      border: none;
      border-radius: 16px;
      padding: 10px 16px 10px 46px;
      outline: none;
      &::-ms-input-placeholder {
        color: var(--gray);
      }
      &::placeholder {
        color: var(--gray);
      }
    }
    > svg {
      position: absolute;
      top: 10.5px;
      left: 16px;
    }
  }
  .resourceViewButton {
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--white);
    padding: 6.5px 16px 6.5px 24px;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.25s;
    &:hover {
      background: var(--greyActive);
      transition: all 0.25s;
    }
  }
  .pipelineButtons {
    > div {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 6px;
      .disabled {
        user-select: none;
        pointer-events: none;
        background: var(--gray);
        padding: 7px 16px;
        border-radius: 4px;
        font-weight: 600;
      }
      .red {
        cursor: pointer;
        padding: 7px 16px;
        border-radius: 4px;
        font-weight: 600;
        background: var(--red);
        transition: all 0.2s;
        &:hover {
          filter: brightness(1.2);
          transition: all 0.2s;
        }
      }
      .gradient {
        cursor: pointer;
        padding: 7px 16px;
        border-radius: 4px;
        font-weight: 600;
        background: var(--gradient);
        transition: all 0.2s;
        &:hover {
          filter: brightness(1.2);
          transition: all 0.2s;
        }
      }
    }
    > p {
      color: var(--gray);
      font-size: 12px;
      font-weight: 300;
      text-align: right;
    }
  }
}

.verticalLine {
  height: 21px;
  border-left: 1px solid var(--gray);
}

.settingIcon {
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.15);
    transition: all 0.2s;
  }
}

.workspaceOption {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  > p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    > span {
      font-size: 12px;
      vertical-align: top;
    }
  }
  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    > svg {
      width: 0;
      flex-shrink: 0;
      transition: all 0.2s;
      > path,
      > rect {
        fill: var(--white);
      }
    }
  }
  &:hover {
    > div {
      > svg {
        width: 24px;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.2);
          transition: all 0.15s;
        }
      }
    }
  }
}
