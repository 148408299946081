.description {
  font-size: 12px;
  font-weight: 300;
  color: var(--gray);
  margin-bottom: 40px;
}

.section {
  margin-bottom: 60px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  background: var(--backgroundDark);
  margin-bottom: 16px;
}

.avatar {
  cursor: pointer;
  position: relative;
  width: 121px;
  height: 121px;
  border-radius: 50%;
  > input {
    display: none;
  }
  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    transition: all 0.25s;
  }
  > svg {
    opacity: 0;
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.25s;
  }
  &:hover {
    > img {
      opacity: 0.5;
      transition: all 0.25s;
    }
    > svg {
      opacity: 1;
      transition: all 0.25s;
    }
  }
}

.avatarButtonsContainer {
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px 24px;
  margin: 0 auto;
}

.changePasswordForm {
  max-width: 280px;
}

.billingButton {
  text-decoration: none;
  > button {
    display: flex;
    align-items: center;
    gap: 8px;
    > svg {
      width: auto;
      height: 24px;
    }
  }
}
