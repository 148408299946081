.container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 500;
  > p {
    &.dislike {
      color: var(--red);
    }
    &.like {
      color: var(--green);
    }
  }
  > svg {
    &.dislike {
      transform: rotate(180deg);
      path {
        fill: var(--red);
      }
    }
    &.like {
      path {
        fill: var(--green);
      }
    }
  }
}
