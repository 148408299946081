.container {
  cursor: pointer;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding: 3px 16px;
  border-radius: 18px;
  border: 3px solid var(--gray);
}
