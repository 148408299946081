.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;
}

.iframeContainer {
  width: 100%;
  position: relative;
  // > .iframe {
  //   width: 100%;
  //   height: 100%;
  // }
  // > .loading {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   background: var(--backgroundLight);
  //   z-index: 1;
  // }
  > .galaxyBackground {
    width: 100%;
    height: 100%;
    z-index: unset;
    > div:last-child {
      width: 100%;
      > .content {
        min-height: 100vh;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 24px;
        color: var(--white);
        span {
          font-size: 50px;
          font-weight: 200;
          letter-spacing: 6px;
          background: -webkit-linear-gradient(#38495a, #ffffff);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .quote {
          background: -webkit-linear-gradient(#ffffff, #38495a);
          background-clip: text;
          -webkit-background-clip: text;
          font-size: 24px;
        }
      }
    }
  }
  @media only screen and (max-width: 767.98px) {
    display: none;
  }
}

.formContainer {
  width: 100%;
  background: var(--backgroundLight);
  overflow-y: auto;
  > .formWrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    > .formContent {
      width: 100%;
      max-width: 550px;
      padding: 36px;
    }
  }
}
