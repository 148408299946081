.container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.main {
  width: calc(100vw - 60px);
  margin-left: 60px;
}
