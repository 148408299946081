.container {
  [class~='react-datepicker-wrapper'] {
    height: 27px;
  }
}

.input {
  height: 20px;
  background: transparent;
  border: none;
  outline: none;
  color: var(--white);
  font-size: 12px;
  font-weight: 500;
  padding-left: 32px !important;
  min-width: 110px;
  text-align: left;
}

.calendar {
  [class~='react-datepicker__triangle'] {
    &::after {
      border-bottom-color: var(--backgroundDark) !important;
    }
  }
  [class~='react-datepicker__header'] {
    background: var(--backgroundDark);
  }
  [class~='react-datepicker__month'] {
    margin: 0;
    padding: 8px;
    background: var(--backgroundDark);
  }
  [class~='react-datepicker__current-month'],
  [class~='react-datepicker__day-name'],
  [class~='react-datepicker__day'] {
    color: var(--white);
  }
  [class~='react-datepicker__day--today'] {
    color: var(--primary);
  }
  [class~='react-datepicker__day'] {
    border: 1px solid transparent;
    transition: all 0.15s;
    &:hover {
      background: transparent;
      border: 1px solid var(--white);
      transition: all 0.15s;
    }
  }
  [class~='react-datepicker__day--keyboard-selected'] {
    background: transparent;
    transition: all 0.15s;
  }
  [class~='react-datepicker__day--selected'] {
    font-weight: 600;
    color: var(--white);
    background: var(--primary);
    transition: all 0.15s;
  }
}

.icon {
  cursor: pointer;
  padding: 5.5px 8px !important;
}
