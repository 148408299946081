.container {
  padding: 64px 0;
}

.archiveContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
  p {
    font-size: 14px;
    font-weight: 300;
  }
  [class~='MuiStack-root'] {
    gap: 6px;
    [class~='Mui-checked'] {
      color: var(--green) !important;
    }
    [class~='MuiSwitch-track'] {
      opacity: 0.5;
      background-color: var(--greenActive) !important;
    }
    [class~='Mui-checked'] + [class~='MuiSwitch-track'] {
      opacity: 1;
    }
  }
  &.hide {
    opacity: 0;
    user-select: none;
    pointer-events: none;
  }
}

.projectList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 60px;
  margin-top: 32px;
  > svg {
    width: 320px;
    height: 320px;
    transition: all 0.2s;
    &:hover {
      opacity: 0.5;
      transition: all 0.2s;
    }
  }
  > .project {
    width: 320px;
    height: 320px;
    border-radius: 12px;
    display: flex;
    align-items: flex-end;
    padding: 16px;
    position: relative;
    overflow: visible;
    > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: all 0.2s;
      border-radius: 12px;
    }
    > p {
      font-weight: 600;
      z-index: 1;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    > .mostRecent {
      display: flex;
      align-items: center;
      gap: 16px;
      pointer-events: none;
      z-index: 1;
      font-size: 16px;
      font-weight: 300;
      margin: 0;
      padding: 0;
      color: var(--white);
      position: absolute;
      top: -32px;
      left: 0;
      transform-origin: top right;
      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--primary);
      }
    }
  }
  .editIcon {
    background: var(--primary);
    height: 40px;
    width: 40px;
    padding: 6px;
    border-radius: 6px;
    opacity: 0;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    transition: all 0.2s;
  }
  .unarchiveButton {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    z-index: 1;
    transition: all 0.2s;
  }
  > *:not([class~='MuiSkeleton-root']):not(.noProject) {
    cursor: pointer;
    border-radius: 12px;
    transition: all 0.2s;
    &:hover {
      > img,
      > div:not(.mostRecent) {
        opacity: 0.5;
        transition: all 0.2s;
      }
      .editIcon {
        opacity: 1;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.1);
          transition: all 0.2s;
        }
      }
      .unarchiveButton {
        opacity: 1;
        transition: all 0.2s;
      }
    }
  }
}

.noProject {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;
  width: 100%;
  margin: auto;
  max-width: 500px;
  > p {
    font-size: 38px;
    font-weight: 600;
    line-height: 100%;
  }
}
