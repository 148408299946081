.container {
  display: block;
  font-weight: 600;
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--white);
  color: var(--white);
  background: transparent;
  padding: 6px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.25s;
  &:disabled {
    user-select: none;
    pointer-events: none;
    background: var(--gray);
  }
  &:hover {
    background: var(--greyActive);
    transition: all 0.25s;
  }
}
