.popover {
  z-index: 999;
}

.paper {
  background: transparent !important;
  overflow: visible !important;
  border-radius: 8px !important;
  box-shadow: var(--blackBoxShadow) !important;
}

.menu {
  width: 275px;
  background: var(--black);
  color: var(--white);
  border-radius: 8px;
  padding: 16px 24px;
  .searchInput {
    width: 100%;
    color: var(--gray);
    background: var(--backgroundLight);
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 16px;
    padding: 10px 16px;
    outline: none;
    margin-bottom: 16px;
    &::-ms-input-placeholder {
      color: var(--gray);
    }
    &::placeholder {
      color: var(--gray);
    }
  }
  .userList {
    overflow-y: auto;
    height: 250px;
    margin-bottom: 16px;
    .user {
      cursor: pointer;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 8px;
      transition: all 0.2s;
      > img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
      > p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        font-weight: 500;
      }
      &:hover {
        background: var(--rowBackground);
        transition: all 0.2s;
      }
    }
  }
  .addUserButton {
    width: 100%;
    background: transparent;
    border: 1px solid var(--white);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    transition: all 0.2s;
    > svg {
      > path {
        fill: var(--white);
      }
    }
    &:hover {
      background: var(--greyActive);
      transition: all 0.2s;
    }
  }
}
