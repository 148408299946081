.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  > label {
    font-size: 12px;
    font-weight: 600;
    margin: 0 2px 2px;
    > span {
      font-weight: 500;
    }
  }
  > input {
    width: 100%;
    padding: 10px 16px;
    font-size: 12px;
    font-weight: 300;
    color: var(--white) !important;
    background: transparent !important;
    border-radius: 8px;
    border: 1px solid var(--primary);
    &::-ms-input-placeholder {
      color: var(--gray);
      font-size: 12px;
      font-weight: 300;
    }
    &::placeholder {
      color: var(--gray);
      font-size: 12px;
      font-weight: 300;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-webkit-autofill::first-line {
      transition: background-color 0s 600000s, color 0s 600000s;
    }
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: var(--primary);
    }
  }
  .subLabel {
    margin: 6px 2px 0;
    font-size: 12px;
    font-style: italic;
    color: var(--gray);
  }
  .errorMessage {
    margin: 2px 2px 0;
    font-size: 12px;
    color: var(--danger);
    white-space: wrap;
  }
}

.isPassword {
  position: relative;
  > input {
    padding: 10px 48px 10px 16px;
  }
  > svg {
    cursor: pointer;
    position: absolute;
    right: 16px;
    &.passwordShow {
      top: 33px;
    }
    &.passwordHide {
      top: 32px;
    }
  }
}
