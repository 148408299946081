.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--backgroundLight);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  word-break: break-all;
  text-align: center;
  padding: 16px;
  transition: all 0.2s;
  border-radius: 12px;
}
