.popover {
  z-index: 999;
}

.paper {
  background: transparent !important;
  overflow: visible !important;
  border-radius: 8px !important;
  box-shadow: var(--blackBoxShadow) !important;
}

.menu {
  background: var(--black);
  color: var(--white);
  border-radius: 8px;
  padding: 16px 24px;
  > div {
    cursor: pointer;
    padding: 14px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s;
    &:hover {
      background: var(--rowBackground);
      transition: all 0.2s;
    }
  }
}
