@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.container {
  height: calc(100vh - 24px - 60px - 4px);
  min-height: 500px;
  padding-bottom: 60px;
}

.description {
  font-size: 12px;
  font-weight: 300;
  color: var(--gray);
  margin-bottom: 14px;
}

.header {
  display: flex;
  align-items: flex-end;
  overflow-x: auto;
  .headerItem {
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--pink);
    background: var(--pinkBackground);
    font-size: 12px;
    font-weight: 600;
    padding: 8px 24px;
    border-radius: 12px 12px 0 0;
    border: 1px solid var(--reactFlowBorder);
    transition: all 0.25s;
    .actionButtons {
      display: none;
    }
    &.active {
      color: var(--white);
      background: var(--secondary);
      .actionButtons {
        display: flex;
        align-items: center;
        gap: 6px;
        > svg {
          width: 18px;
          height: 18px;
          transition: all 0.2s;
          path,
          rect {
            fill: var(--white);
          }
          &:hover {
            transform: scale(1.2);
            transition: all 0.2s;
          }
        }
      }
    }
    &.addPipelineButton {
      display: flex;
      align-items: center;
      gap: 10px;
      border-color: transparent;
      background: transparent;
      white-space: nowrap;
    }
    path,
    rect {
      transition: all 0.25s;
    }
    &:hover {
      color: var(--white);
      background: var(--secondary);
      transition: all 0.25s;
      path,
      rect {
        fill: var(--white);
        transition: all 0.25s;
      }
    }
  }
}

.reactflow {
  position: relative;
  width: 100%;
  height: calc(100% - 18px - 14px - 36px - 10px); // 10px is for horizontal scrollbar of .header
  border-radius: 0 0 24px 24px;
  border: 1px solid var(--reactFlowBorder);
  overflow: hidden;
  &.showHistory {
    margin-bottom: 28px;
    height: calc(100% - 18px - 14px - 36px - 10px - 28px - 90px); // 10px is for horizontal scrollbar of .header
  }
  &.isPreview {
    border: 3px solid var(--warning);
  }
  [class~='react-flow__attribution'] {
    display: none;
  }
  [class~='react-flow__handle'] {
    background: var(--nodeOutline);
    border-color: var(--nodeOutline);
  }
  [class~='react-flow__node'][class~='selected'] {
    > div {
      box-shadow: var(--primaryBoxShadow);
    }
  }
  // .savingContainer {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   padding: 7px 16px;
  //   border-radius: 0 0 4px 0;
  //   white-space: nowrap;
  //   transition: all 0.25s;
  //   z-index: 1;
  //   display: flex;
  //   align-items: center;
  //   gap: 8px;
  //   background: var(--greyActive);
  //   > svg {
  //     fill: var(--white);
  //     width: 14px;
  //     height: 14px;
  //     -webkit-animation: rotation 2s infinite linear;
  //     animation: rotation 2s infinite linear;
  //   }
  //   > p {
  //     font-size: 14px;
  //   }
  // }
  .previewContainer {
    cursor: default;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 600;
    color: var(--black);
    background: var(--warning);
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
  }
  .historyButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px 16px;
    border-radius: 4px;
    border: 1px solid var(--white);
    background-color: var(--black);
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 1;
    transition: opacity 0.1s;
    > p {
      width: 102px;
      font-weight: 600;
      line-height: 19.36px;
    }
    > svg {
      width: 18px;
      height: 18px;
    }
    &.hide {
      padding: 0;
      opacity: 0;
      transition: opacity 0.1s;
    }
  }
}

.historyContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 95px;
  .historyScroll {
    padding-bottom: 8px;
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
    .historyItem {
      cursor: pointer;
      width: 170px;
      height: 90px;
      padding: 16px;
      border-radius: 20px;
      border: 1px solid var(--white);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      &.published {
        border: 1px solid var(--green);
      }
      &.active {
        border: 1px solid var(--warning);
      }
      .historyInfo {
        width: calc(100% - 24px - 12px);
        display: flex;
        flex-direction: column;
        gap: 24px;
        .historyDate {
          font-size: 12px;
          font-weight: 300;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .historyUser {
          display: flex;
          align-items: center;
          gap: 8px;
          > img {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            object-fit: cover;
            object-position: center;
          }
          > p {
            font-size: 12px;
            font-weight: 500;
            color: var(--gray);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      &:hover {
        background: var(--greyActive);
        transition: all 0.25s;
      }
    }
    .line {
      flex-shrink: 0;
      width: 36px;
      height: 1px;
      background: var(--white);
    }
  }
  .historyDraft {
    cursor: pointer;
    margin-bottom: 8px;
    flex-shrink: 0;
    width: 170px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px dashed var(--white);
    color: var(--gray);
    font-size: 12px;
    font-weight: 500;
    transition: all 0.25s;
    &.active {
      border-color: var(--warning);
    }
    &:hover {
      background: var(--greyActive);
      transition: all 0.25s;
    }
  }
}

.newStageTooltip {
  font-size: 14px;
}

.toolbar {
  position: fixed;
  z-index: 9;
  bottom: 92px;
  margin-left: calc((60px + 240px) / 2);
  left: 50%;
  transform: translateX(-50%);
  background: var(--backgroundLight);
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  transition: background-color 0.1s;
  gap: 8px;
  .newStageButton {
    cursor: pointer;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    background: var(--nodeBackground);
    border-radius: 12px;
    border: 1px solid var(--nodeOutline);
    font-weight: 500;
    white-space: nowrap;
    transition: filter 0.25s, opacity 0.1s;
    > p {
      width: 84px;
    }
    &.hide {
      padding: 0;
      opacity: 0;
      border: none;
      transition: opacity 0.1s;
    }
    &.disabled {
      user-select: none;
      pointer-events: none;
      opacity: 0.75;
      > svg {
        fill: var(--white);
        width: 28px;
        height: 28px;
        -webkit-animation: rotation 2s infinite linear;
        animation: rotation 2s infinite linear;
      }
    }
    &:hover {
      filter: brightness(1.15);
      transition: filter 0.25s;
    }
  }
  .tooltip {
    width: 178px; // width of new stage button
    font-size: 12px;
    text-align: center;
    color: var(--gray);
    line-height: 100%;
    transition: opacity 0.1s;
    &.hide {
      opacity: 0;
      transition: opacity 0.1s;
    }
  }
  &.hide {
    background-color: transparent;
    transition: background-color 0.1s;
  }
  &.showHistory {
    bottom: calc(92px + 28px + 90px);
  }
}

.noPipeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;
  width: 100%;
  margin: auto;
  padding: 128px 0 64px;
  max-width: 500px;
  > p {
    font-size: 38px;
    font-weight: 600;
    line-height: 100%;
  }
}

.nodeSidebarContainer {
  position: fixed !important;
  top: 0;
  right: 0;
  min-height: 100vh;
  border: none !important;
  z-index: 1000;
  box-shadow: var(--leftBoxShadow);
  &[class~='ps-collapsed'] {
    width: 0 !important;
    min-width: 0 !important;
  }
  .nodeSidebar {
    padding: 0 40px 40px;
    direction: ltr;
    background-color: var(--backgroundLight);
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .backContainer {
      position: sticky;
      top: 0px;
      right: 0;
      width: 100%;
      padding: 40px 0 24px;
      background: var(--backgroundLight);
      z-index: 1;
      > svg {
        cursor: pointer;
        transition: all 0.25s;
        &:hover {
          transform: scale(1.2);
          transition: all 0.25s;
        }
      }
    }
    .appSettingsContainer {
      margin-bottom: 24px;
      .appSettings {
        margin-bottom: 8px;
        display: flex;
        align-items: flex-end;
        gap: 8px;
        > div {
          flex: 1;
        }
        > svg {
          cursor: pointer;
          height: 38px; // to make all the items aligned center
          transition: all 0.2s;
          > path,
          > rect {
            fill: var(--white);
          }
          &:hover {
            transform: scale(1.2);
            transition: all 0.2s;
          }
        }
      }
    }
    hr {
      margin: 24px 0 12px;
      border-color: var(--grey);
      opacity: 1;
    }
    .titleContainer {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 10px;
      &.editing {
        align-items: flex-start;
      }
      > p {
        cursor: default;
        user-select: none;
        font-size: 24px;
        font-weight: 700;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > svg {
        cursor: pointer;
        flex-shrink: 0;
        transition: all 0.25s;
        &:hover {
          transform: scale(1.2);
          transition: all 0.25s;
        }
      }
    }
    .subtitle {
      font-weight: 300;
      margin-bottom: 12px;
    }
    .fileTypeListContainer {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: var(--primary);
        }
      }
      > div {
        border-radius: 8px;
        border: 1px solid var(--primary);
        padding: 6px 10px 6px 8px;
        word-break: break-all;
        display: flex;
        align-items: flex-start;
        gap: 8px;
        font-size: 14px;
        font-weight: 500;
        color: var(--gray);
        &:hover {
          cursor: default;
          > svg:last-child {
            cursor: pointer;
            transition: all 0.25s;
            &:hover {
              transform: scale(1.2);
              transition: all 0.25s;
            }
          }
        }
      }
    }
  }
}

.projectSidebarContainer {
  position: fixed !important;
  top: 0;
  right: 0;
  min-height: 100vh;
  border: none !important;
  z-index: 1000;
  box-shadow: var(--leftBoxShadow);
  &[class~='ps-collapsed'] {
    width: 0 !important;
    min-width: 0 !important;
  }
  .projectSidebar {
    padding: 0 40px 40px;
    direction: ltr;
    background-color: var(--backgroundLight);
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .backContainer {
      position: sticky;
      top: 0px;
      right: 0;
      width: 100%;
      padding: 40px 0 24px;
      background: var(--backgroundLight);
      z-index: 1;
      > svg {
        cursor: pointer;
        transition: all 0.25s;
        &:hover {
          transform: scale(1.2);
          transition: all 0.25s;
        }
      }
    }
    .appSettingsContainer {
      margin-bottom: 24px;
      .appSettings {
        margin-bottom: 8px;
        display: flex;
        align-items: flex-end;
        gap: 8px;
        > div {
          flex: 1;
        }
        > svg {
          cursor: pointer;
          height: 38px; // to make all the items aligned center
          transition: all 0.2s;
          > path,
          > rect {
            fill: var(--white);
          }
          &:hover {
            transform: scale(1.2);
            transition: all 0.2s;
          }
        }
      }
    }
    .addAppSettingsButton {
      width: 100%;
      background: transparent;
      border: 1px solid var(--primary);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 6px;
      transition: all 0.2s;
      > svg {
        > path {
          fill: var(--primary);
        }
      }
      &:hover {
        background: var(--greyActive);
        transition: all 0.2s;
      }
    }
    hr {
      margin: 24px 0;
      border-color: var(--grey);
      opacity: 1;
    }
    .title {
      font-weight: 600;
      margin-bottom: 4px;
      white-space: nowrap;
    }
    .description {
      font-size: 12px;
      color: var(--gray);
      margin-bottom: 12px;
      white-space: nowrap;
    }
    .menu {
      .editContainer {
        width: 100%;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
      }
      .statusInput {
        flex: 1;
        padding: 0 16px;
        input {
          font-size: 12px;
          font-weight: 500;
          color: var(--white);
          border-bottom: 1px solid var(--white);
        }
      }
      .deleteIcon {
        cursor: pointer;
        margin-right: 12px;
        transition: all 0.2s;
        > path,
        > rect {
          fill: var(--white);
        }
        &:hover {
          transform: scale(1.2);
          transition: all 0.2s;
        }
      }
    }
    .statusItem {
      cursor: default;
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      border-width: 2px;
      border-style: solid;
      border-radius: 16px;
      padding: 6.5px 16px;
      transition: all 0.2s;
      &:hover {
        background: var(--greyActive);
        transition: all 0.2s;
      }
    }
    .editLabel {
      cursor: pointer;
      width: fit-content;
      margin: 16px auto 0;
      font-weight: 500;
      transition: all 0.2s;
      &:hover {
        transform: scale(1.1);
        transition: all 0.2s;
      }
    }
  }
}

.pipelineSidebarContainer {
  position: fixed !important;
  top: 0;
  right: 0;
  min-height: 100vh;
  border: none !important;
  z-index: 1000;
  box-shadow: var(--leftBoxShadow);
  &[class~='ps-collapsed'] {
    width: 0 !important;
    min-width: 0 !important;
  }
  .pipelineSidebar {
    padding: 0 40px 40px;
    direction: ltr;
    background-color: var(--backgroundLight);
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .backContainer {
      position: sticky;
      top: 0px;
      right: 0;
      width: 100%;
      padding: 40px 0 24px;
      background: var(--backgroundLight);
      z-index: 1;
      > svg {
        cursor: pointer;
        transition: all 0.25s;
        &:hover {
          transform: scale(1.2);
          transition: all 0.25s;
        }
      }
    }
    .titleContainer {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 10px;
      &.editing {
        align-items: flex-start;
      }
      > p {
        cursor: default;
        user-select: none;
        font-size: 24px;
        font-weight: 700;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > svg {
        cursor: pointer;
        flex-shrink: 0;
        transition: all 0.25s;
        &:hover {
          transform: scale(1.2);
          transition: all 0.25s;
        }
      }
    }
    .useParentContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        font-size: 14px;
        font-weight: 300;
        white-space: nowrap;
      }
      [class~='MuiStack-root'] {
        gap: 6px;
        [class~='Mui-checked'] {
          color: var(--green) !important;
        }
        [class~='MuiSwitch-track'] {
          opacity: 0.5;
          background-color: var(--greenActive) !important;
        }
        [class~='Mui-checked'] + [class~='MuiSwitch-track'] {
          opacity: 1;
        }
      }
    }
    .settingStackContainer {
      height: 24px;
      margin-bottom: 4px;
      .title {
        margin: 0;
      }
    }
    .appSettingsContainer {
      margin-bottom: 24px;
      .appSettings {
        margin-bottom: 8px;
        display: flex;
        align-items: flex-end;
        gap: 8px;
        > div {
          flex: 1;
        }
        > svg {
          cursor: pointer;
          height: 38px; // to make all the items aligned center
          transition: all 0.2s;
          > path,
          > rect {
            fill: var(--white);
          }
          &:hover {
            transform: scale(1.2);
            transition: all 0.2s;
          }
        }
      }
    }
    .addAppSettingsButton {
      width: 100%;
      background: transparent;
      border: 1px solid var(--primary);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 6px;
      margin-top: 24px;
      transition: all 0.2s;
      > svg {
        > path {
          fill: var(--primary);
        }
      }
      &:hover {
        background: var(--greyActive);
        transition: all 0.2s;
      }
    }
    hr {
      margin: 24px 0;
      border-color: var(--grey);
      opacity: 1;
    }
    .title {
      font-weight: 600;
      margin-bottom: 4px;
      white-space: nowrap;
    }
    .description {
      font-size: 12px;
      color: var(--gray);
      margin-bottom: 12px;
      white-space: nowrap;
    }
    .menu {
      .editContainer {
        width: 100%;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
      }
      .statusInput {
        flex: 1;
        padding: 0 16px;
        input {
          font-size: 12px;
          font-weight: 500;
          color: var(--white);
          border-bottom: 1px solid var(--white);
        }
      }
      .deleteIcon {
        cursor: pointer;
        margin-right: 12px;
        transition: all 0.2s;
        > path,
        > rect {
          fill: var(--white);
        }
        &:hover {
          transform: scale(1.2);
          transition: all 0.2s;
        }
      }
    }
    .statusItem {
      cursor: default;
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      border-width: 2px;
      border-style: solid;
      border-radius: 16px;
      padding: 6.5px 16px;
      transition: all 0.2s;
      &:hover {
        background: var(--greyActive);
        transition: all 0.2s;
      }
    }
    .editLabel {
      cursor: pointer;
      width: fit-content;
      margin: 16px auto 0;
      font-weight: 500;
      transition: all 0.2s;
      &:hover {
        transform: scale(1.1);
        transition: all 0.2s;
      }
    }
  }
}
