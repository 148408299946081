.container {
  padding-bottom: 40px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 28px;
  margin-bottom: 24px;
  white-space: nowrap;
}

.toolbarLeft {
  display: flex;
  align-items: center;
  gap: 16px;
}

.toolbarRight {
  display: flex;
  align-items: center;
  gap: 28px;
  .addUserButton {
    display: flex;
    align-items: center;
    gap: 8px;
    > svg {
      width: 14px;
      height: 14px;
    }
  }
}

.searchInput {
  width: 280px;
  color: var(--gray);
  background: var(--backgroundLight);
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 16px;
  padding: 10px 16px;
  outline: none;
  &::-ms-input-placeholder {
    color: var(--gray);
  }
  &::placeholder {
    color: var(--gray);
  }
}

.table {
  overflow: auto;
  .row {
    display: flex;
    align-items: stretch;
  }
  .column {
    flex-shrink: 0;
    min-width: 172px;
    flex: 1;
  }
  .head {
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: sticky;
    top: 0;
    width: 100%;
    min-width: fit-content;
    background: var(--backgroundDark);
    z-index: 1;
    .column {
      cursor: default;
      padding: 8.5px 16px;
      font-size: 12px;
      font-weight: 600;
      color: var(--gray);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:first-child {
        position: sticky;
        left: 0;
        background: var(--backgroundDark); // for position sticky
        box-shadow: var(--rightBoxShadow);
      }
      &.sort {
        cursor: pointer;
        height: 30px;
        padding: 2px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        transition: all 0.25s;
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 8px;
          .revert {
            transform: rotate(180deg);
          }
        }
        &:hover {
          background: var(--backgroundLight);
          transition: all 0.25s;
        }
      }
    }
  }
  .bodyContainer {
    min-height: 500px;
    // height: calc(100vh - 24px - 40px - 24px - 38px - 24px - 32px - 40px - 10px); // 10px for 1 horizontal scrollbar
    height: calc(100vh - 24px - 40px - 24px - 32px - 40px - 10px); // 10px for 1 horizontal scrollbar
  }
  .body {
    border-radius: 8px;
    padding: 0 20px;
    min-height: 100%;
    min-width: fit-content;
    width: 100%;
    background: var(--backgroundLight);
    &.empty {
      min-width: 1072px; // min-width of table head
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .row {
      > .column:first-child {
        border-radius: 8px 0 0 8px;
      }
      > .column:last-child {
        border-radius: 0 8px 8px 0;
      }
      &:nth-child(odd) {
        > .column {
          background: var(--backgroundLight);
          transition: all 0.2s;
        }
      }
      &:nth-child(even) {
        > .column {
          background: var(--rowBackground);
          transition: all 0.2s;
        }
      }
      &:hover {
        > .column {
          background: var(--tableRowActive) !important;
          transition: all 0.2s;
        }
      }
    }
    .column {
      font-weight: 500;
      padding: 12px 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:first-child {
        display: flex;
        align-items: center;
        position: sticky;
        left: 0;
        box-shadow: var(--rightBoxShadow);
      }
      &.email {
        display: flex;
        align-items: center;
        gap: 8px;
        > p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > svg {
          cursor: pointer;
          flex-shrink: 0;
          width: 20px;
          height: 20px;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.2);
            transition: all 0.2s;
          }
        }
      }
      // &.role {
      //   padding: 10px 16px;
      // }
      .skillInput {
        border: none;
        background: transparent;
        color: var(--white);
        outline: none;
        font-weight: 500;
      }
    }
  }
}

.customValueContainer {
  display: block !important;
  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > input {
    position: absolute;
  }
}

.customOption {
  display: flex;
  align-items: center;
  gap: 4px;
  > input {
    cursor: pointer;
    position: relative;
    border: 2px solid var(--white);
    border-radius: 2px;
    background: none;
    line-height: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: text-top;
    height: 18px;
    width: 18px;
    appearance: none;
    -webkit-appearance: none;
    &:checked {
      background: var(--primary);
      border-color: var(--primary);
      &::before {
        display: block;
      }
    }
    &::before {
      content: '';
      display: none;
      position: absolute;
      right: 50%;
      top: 50%;
      width: 5px;
      height: 10px;
      border: solid var(--backgroundDark);
      border-width: 0 2px 2px 0;
      margin: -1px;
      transform: rotate(45deg) translate(-50%, -50%);
      z-index: 1;
    }
  }
}
