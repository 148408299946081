.title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 32px;
}

.backButton {
  padding: 8px 20px;
  margin: 0 auto;
}
