.title {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 24px;
}

.line {
  opacity: 0.7;
  margin: 16px 0 !important;
  border-color: var(--white) !important;
}
